import { inView, animate } from "motion";

const toAnimate = document.querySelectorAll(".anim-fade-up");

// Animate Text to Fade Up
toAnimate.forEach((item) => {
  item.style.opacity = "0";
  item.style.transform = "translateY(70px)";
  
  inView(item, 
    ({ target }) => {
      animate(
        target,
        { 
          opacity: [0, 1], 
          transform: ["translateY(70px)", "translateY(0)"] 
        },
        { 
          duration: 0.7, 
          easing: "ease-in-out",
        }
      );
    },
  );
});
