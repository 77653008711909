function isTouchDevice() {
  try {
    //We try to create TouchEvent. It would fail for desktops and throw error
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

export default () => ({
  show: false,

  move(e) {
    //Try, catch to avoid any errors for touch screens (Error thrown when user doesn't move his finger)
    const image = this.$refs.image.getBoundingClientRect();
    const r = this.$refs.box.getBoundingClientRect();
    let x = e.clientX;
    let y = e.clientY;

    let _x = (x - r.x - 50) | 1;
    let _y = (y - r.y - 50) | 1;

    //set left and top of div based on mouse position
    this.$refs.image.style.left = `${_x}px`;
    this.$refs.image.style.top = `${_y}px`;
  },
});
